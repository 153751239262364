import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import axios from "axios";
import Plx from "react-plx";
import scrollToComponent from "react-scroll-to-component";
import i18n from "./i18n";
import Helmet from "react-helmet";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Policies } from "./Policies";

const plxData = [
  {
    start: 0,
    end: "self",
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: "",
      loading: true,
      addressVal: "1",
      email: "",
      name: "",
      phone: "",
      message: "",
      formResult: "",
      disableSend: false,
      autoplay: 0,
    };
  }

  componentWillMount() {
    var url = window.location.pathname;
    var subUrl = url.substr(url.lastIndexOf("/") + 1);

    subUrl !== ""
      ? this.setState({ lng: subUrl })
      : this.setState({ lng: "en" });
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState(() => ({ loading: false }), window.scrollTo(0, 0));
    }, 300);
    scrollToComponent(this.refs.name);
    i18n.on("languageChanged", this.onLanguageChanged);
  }

  componentWillUnmount() {
    clearTimeout(this.loaded);
    i18n.off("languageChanged", this.onLanguageChanged);
  }

  onLanguageChanged(lng) {
    //LANGUAGE SELECTION
    console.log("yepp", lng);
    // // document.documentElement.setAttribute('lang', lng)
    // document.head.querySelector("meta[name=description]").setAttribute('content', 'test');
    // document.head.querySelector("meta[name=keywords]").setAttribute('content', 'test');
    this.setState({
      lng: lng,
    });
  }

  is_email(val) {
    //LOOK IF ITS EMAIL
    // eslint-disable-next-line
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(val);
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = (event) => {
    event.preventDefault();
    const { name, email, phone, message } = this.state;

    if (
      name.length >= 3 &&
      this.is_email(email) &&
      phone.length > 0 &&
      message.length > 0
    ) {
      this.setState({
        disableSend: true,
      });
      axios
        .post(
          "https://getform.io/f/da78505e-64a2-4228-ba57-ff285a9bad73",
          this.state,
          { headers: { Accept: "application/json" } }
        )
        .then((response) => {
          this.setState({
            formResult: "success",
            email: "",
            name: "",
            phone: "",
            message: "",
          });
          setTimeout(
            function () {
              this.setState({ formResult: "", disableSend: false });
            }.bind(this),
            300
          );
        })
        .catch((error) => {
          this.setState({
            formResult: "failed",
            email: "",
            name: "",
            phone: "",
            message: "",
          });
          setTimeout(
            function () {
              this.setState({ formResult: "", disableSend: false });
            }.bind(this),
            300
          );
        });
    } else {
      this.setState({
        formResult: "failed",
      });
      setTimeout(
        function () {
          this.setState({ formResult: "" });
        }.bind(this),
        300
      );
    }
  };

  App = () => {
    //APP IS SEPERATED FOR LANG PATH
    let lng = this.state.lng;
    let val = this.state.addressVal;
    let autoplay = this.state.autoplay;
    const disableSend = this.state.disableSend;
    const video =
      "https://www.youtube.com/embed/XuhW6Ze4tfo?rel=0&cc_load_policy=1&autoplay=" +
      autoplay;
    return (
      <div>
        <section
          id="about"
          ref={(section) => {
            this.About = section;
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div
                className={"video-container " + (autoplay ? "show-video" : "")}
              >
                <div className="video">
                  <iframe
                    width="100%"
                    height="100%"
                    src={video}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="front">
                  <div className="left">
                    <i onClick={() => this.setState({ autoplay: 1 })}></i>
                  </div>
                  <div className="right">
                    <h2>{i18n.t("about.title", { lng })}</h2>
                    <span onClick={() => this.setState({ autoplay: 1 })}>
                      {i18n.t("about.watch", { lng })}
                    </span>
                  </div>
                </div>
              </div>
              <p
                className="video-description"
                dangerouslySetInnerHTML={{
                  __html: i18n.t("about.text", { lng }),
                }}
              ></p>
            </div>
          </div>
        </section>
        <section
          id="properties"
          className="bg"
          ref={(ref) => (this.myRef = ref)}
        >
          <Plx parallaxData={plxData} className="prop container-fluid">
            <h2>{i18n.t("properties.title", { lng })}</h2>
            <Accordion className="accordion left">
              <AccordionItem expanded="true">
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion1.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion1.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion2.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion2.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion3.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion3.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion4.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion4.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion5.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion5.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion6.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion6.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion7.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion7.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion8.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion8.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion9.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion9.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion10.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion10.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion11.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion11.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <h4>{i18n.t("properties.accordion12.title", { lng })}</h4>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>{i18n.t("properties.accordion12.text", { lng })}</p>
                </AccordionItemBody>
              </AccordionItem>
            </Accordion>
          </Plx>
          <Plx parallaxData={plxData} className="ocr container-fluid">
            <h2 className="right">{i18n.t("properties.ocr.title", { lng })}</h2>
            <div className="left block-holder">
              <div className="left blocks block1"></div>
              <div className="left blocks block2"></div>
              <div className="left blocks block3"></div>
            </div>
            <div className="prop-holder">
              <img src={"/images/ocr-pic.svg"} alt=""></img>
              <div className="props prop1 left">
                <span className=""></span>
                <p>{i18n.t("properties.ocr.text1", { lng })}</p>
              </div>
              <div className="props prop2 right">
                <span className="fas fa-laptop"></span>
                <p>{i18n.t("properties.ocr.text2", { lng })}</p>
              </div>
              <div className="props prop3 right">
                <span className="fas fa-user"></span>
                <p>{i18n.t("properties.ocr.text3", { lng })}</p>
              </div>
            </div>
          </Plx>
        </section>
        <section id="solutions">
          <div className="container-fluid">
            <div className="scroll-container">
              <Plx parallaxData={plxData} className="row">
                <h2>{i18n.t("solutions.title", { lng })}</h2>
                <div className="columns">
                  <div className="left text col-sm-6 col-xs-12">
                    <h4>{i18n.t("solutions.list1.title", { lng })}</h4>
                    <p>
                      <i className="fas fa-check" />
                      {i18n.t("solutions.list1.text1", { lng })}
                    </p>
                    <p>
                      <i className="fas fa-check" />
                      {i18n.t("solutions.list1.text2", { lng })}
                    </p>
                    <p>
                      <i className="fas fa-check" />
                      {i18n.t("solutions.list1.text3", { lng })}
                    </p>
                    <p>
                      <i className="fas fa-check" />
                      {i18n.t("solutions.list1.text4", { lng })}
                    </p>
                    <p>
                      <i className="fas fa-check" />
                      {i18n.t("solutions.list1.text5", { lng })}
                    </p>
                    <p>
                      <i className="fas fa-check" />
                      {i18n.t("solutions.list1.text6", { lng })}
                    </p>
                    <p>
                      <i className="fas fa-check" />
                      {i18n.t("solutions.list1.text7", { lng })}
                    </p>
                    <p>
                      <i className="fas fa-check" />
                      {i18n.t("solutions.list1.text8", { lng })}
                    </p>
                  </div>
                  <div className="right img col-sm-6 col-xs-12">
                    <div className="municiple pic">
                      <div className="bg"></div>
                      <img src={"/images/municiple.svg"} alt="municiple" />
                    </div>
                  </div>
                </div>
              </Plx>
            </div>
            <div className="scroll-container">
              <Plx parallaxData={plxData} className="row">
                <div className="left img col-sm-6 col-xs-12">
                  <div className="law pic">
                    <div className="bg"></div>
                    <img src={"/images/law.svg"} alt="law" />
                  </div>
                </div>
                <div className="right text col-sm-6 col-xs-12">
                  <h4>{i18n.t("solutions.list2.title", { lng })}</h4>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list2.text1", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list2.text2", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list2.text3", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list2.text4", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list2.text5", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list2.text6", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list2.text7", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list2.text8", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list2.text9", { lng })}
                  </p>
                </div>
              </Plx>
            </div>
            <div className="scroll-container">
              <Plx parallaxData={plxData} className="row">
                <div className="left text col-sm-6 col-xs-12">
                  <h4>{i18n.t("solutions.list3.title", { lng })}</h4>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list3.text1", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list3.text2", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list3.text3", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list3.text4", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list3.text5", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list3.text6", { lng })}
                  </p>
                  <p>
                    <i className="fas fa-check" />
                    {i18n.t("solutions.list3.text7", { lng })}
                  </p>
                </div>
                <div className="right img col-sm-6 col-xs-12">
                  <div className="construct pic">
                    <div className="bg"></div>
                    <img src={"/images/construct.svg"} alt="construct" />
                  </div>
                </div>
              </Plx>
            </div>
          </div>
        </section>
        <section id="others">
          <Plx parallaxData={plxData} className="container-fluid">
            <h4>{i18n.t("others.title", { lng })}</h4>
            <p>
              {i18n.t("others.text1", { lng })}
              <br />
              {i18n.t("others.text2", { lng })}
            </p>
            <p className="mobileP">{i18n.t("others.mobileText", { lng })}</p>
            <div className="wrapper">
              <div className="center-prop">
                <div className="bgi bg1"></div>
                <div className="bgi bg2"></div>
                <div className="bgi bg3"></div>
                <div className="bgi bg4"></div>
                <div className="bgi bg5"></div>
                <img src={"/images/logo.svg"} alt="logo"></img>
              </div>
              <div className="prop p1">
                <img src="/images/prop1.svg" alt="prop1" />
              </div>
              <div className="prop p2">
                <img src="/images/prop2.svg" alt="prop2" />
              </div>
              <div className="prop p3">
                <img src="/images/prop3.svg" alt="prop3" />
              </div>
              <div className="prop p4">
                <img src="/images/prop4.svg" alt="prop4" />
              </div>
              <div className="prop p5">
                <img src="/images/prop5.svg" alt="prop5" />
              </div>
              <div className="prop p6">
                <img src="/images/prop6.svg" alt="prop6" />
              </div>
              <div className="prop p7">
                <img src="/images/prop7.svg" alt="prop7" />
              </div>
              <div className="prop p8">
                <img src="/images/prop8.svg" alt="prop8" />
              </div>
              <div className="prop p9">
                <img src="/images/prop9.svg" alt="prop9" />
              </div>
              <h6 className="h1">{i18n.t("others.prop1", { lng })}</h6>
              <h6 className="h2">{i18n.t("others.prop2", { lng })}</h6>
              <h6 className="h3">{i18n.t("others.prop3", { lng })}</h6>
              <h6 className="h4">{i18n.t("others.prop4", { lng })}</h6>
              <h6 className="h5">{i18n.t("others.prop5", { lng })}</h6>
              <h6 className="h6">{i18n.t("others.prop6", { lng })}</h6>
              <h6 className="h7">{i18n.t("others.prop7", { lng })}</h6>
              <h6 className="h8">{i18n.t("others.prop8", { lng })}</h6>
            </div>
          </Plx>
        </section>
        <section id="advantages" className="bg">
          <Plx parallaxData={plxData} className="container-fluid">
            <h2>{i18n.t("advantages.title", { lng })}</h2>
            <div className="row">
              <div className="left col-md-6">
                <div className="para p1">
                  <h4>{i18n.t("advantages.para1.title", { lng })}</h4>
                  <p>{i18n.t("advantages.para1.text", { lng })}</p>
                </div>
                <div className="para p2">
                  <h4>{i18n.t("advantages.para2.title", { lng })}</h4>
                  <p>{i18n.t("advantages.para2.text", { lng })}</p>
                </div>
                <div className="para p3">
                  <h4>{i18n.t("advantages.para3.title", { lng })}</h4>
                  <p>{i18n.t("advantages.para3.text", { lng })}</p>
                </div>
              </div>
              <div className="right col-md-6">
                <div className="para p4">
                  <h4>{i18n.t("advantages.para4.title", { lng })}</h4>
                  <p>{i18n.t("advantages.para4.text", { lng })}</p>
                </div>
                <div className="para p5">
                  <h4>{i18n.t("advantages.para5.title", { lng })}</h4>
                  <p>{i18n.t("advantages.para5.text", { lng })}</p>
                </div>
                <div className="para p6">
                  <h4>{i18n.t("advantages.para6.title", { lng })}</h4>
                  <p>{i18n.t("advantages.para6.text", { lng })}</p>
                </div>
              </div>
            </div>
          </Plx>
        </section>
        <section id="demo" className="bg">
          <h4>{i18n.t("demo.title", { lng })}</h4>
          <div className="demo-pic">
            <img src={"/images/adabigfiles.png"} alt=""></img>
          </div>
        </section>
        <section id="contact">
          <h2>{i18n.t("contact.title", { lng })}</h2>
          <div className="address">
            <p>{i18n.t("contact.text" + val, { lng })}</p>
            <div className="info">
              <p>
                <i>T</i>
                {i18n.t("contact.tel", { lng })}
              </p>
              <p>
                <i>E</i>
                {i18n.t("contact.mail", { lng })}
              </p>
            </div>
            <div className="nav">
              <span
                className={val === "1" ? "active" : ""}
                onClick={() => this.setState({ addressVal: "1" })}
              ></span>
              <span
                className={val === "2" ? "active" : ""}
                onClick={() => this.setState({ addressVal: "2" })}
              ></span>
              <span
                className={val === "3" ? "active" : ""}
                onClick={() => this.setState({ addressVal: "3" })}
              ></span>
              <span
                className={val === "4" ? "active" : ""}
                onClick={() => this.setState({ addressVal: "4" })}
              ></span>
            </div>
          </div>
          <form onSubmit={this.handleSubmit}>
            <input
              placeholder="Name Surname"
              min-length="3"
              type="text"
              name="name"
              onChange={this.handleChange}
              value={this.state.name}
              required
            ></input>
            <input
              placeholder="E-Mail"
              type="email"
              name="email"
              onChange={this.handleChange}
              value={this.state.email}
              required
            ></input>
            <input
              placeholder="Phone"
              type="tel"
              name="phone"
              onChange={this.handleChange}
              value={this.state.phone}
              required
            ></input>
            <textarea
              placeholder="Your Message"
              type="text"
              name="message"
              onChange={this.handleChange}
              value={this.state.message}
              required
            ></textarea>
            <button disabled={disableSend ? "disabled" : ""} type="submit">
              {i18n.t("contact.button", { lng })}
            </button>
          </form>
        </section>
        <section className="policies">
          <Link to="/acikrizametni">Açık Rıza Metni</Link>
          <Link to="/cerezpolitikasi">Çerez Politikası</Link>
          <Link to="/kvkk">KVKK</Link>
        </section>
      </div>
    );
  };

  render() {
    let { lng, formResult } = this.state;

    return (
      <Router>
        <div>
          <Helmet htmlAttributes={{ lang: this.state.lng }}>
            <meta charSet="utf-8" />
            <title>{i18n.t("seo.title", { lng })}</title>
            <meta
              name="description"
              content={i18n.t("seo.description", { lng })}
            />
            <meta name="keywords" content={i18n.t("seo.keywords", { lng })} />
          </Helmet>
          <div id="loader" className={this.state.loading ? "" : "loaded"}>
            <div className="container-fluid">
              <div className="row">
                <div className="logo">
                  <div className="load-holder">
                    <img src={"/images/logo2.svg"} alt="logo" />
                    <div className="bars bar1"></div>
                    <div className="bars bar2"></div>
                    <div className="bars bar3"></div>
                  </div>
                  <div className="load-text">
                    <div className="curtain"></div>
                    <p>{i18n.t("mainTitle", { lng })}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="alert"
            className={
              formResult === "success"
                ? "success"
                : formResult === "failed"
                ? "failed"
                : " "
            }
          >
            <p>
              {i18n.t(
                formResult === "success"
                  ? "alert.success"
                  : formResult === "failed"
                  ? "alert.failed"
                  : " ",
                { lng }
              )}
            </p>
          </div>
          <header>
            <div className="languages">
              <Link
                to="/tr"
                className={lng === "tr" ? "active" : null}
                onClick={() => this.setState({ lng: "tr" })}
              >
                TR
              </Link>
              <Link
                to="/en"
                className={lng === "en" ? "active" : null}
                onClick={() => this.setState({ lng: "en" })}
              >
                EN
              </Link>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="logo">
                  <img src={"/images/logo.svg"} alt="logo" />
                  <h1>{i18n.t("mainTitle", { lng })}</h1>
                </div>
                <div
                  className="forward"
                  onClick={() =>
                    scrollToComponent(this.About, {
                      offset: 0,
                      align: "center",
                      duration: 300,
                    })
                  }
                >
                  <img src={"/images/forward.svg"} alt="forward" />
                  <img src={"/images/forward.svg"} alt="forward" />
                  <img src={"/images/forward.svg"} alt="forward" />
                </div>
              </div>
            </div>
            <div className="header-bg bg1"></div>
            <div className="header-bg bg2"></div>
            <div className="header-bg bg3"></div>
            <div className="header-bg bg4"></div>
            <div className="header-bg bg5"></div>
          </header>
          <Route exact path="/" component={this.App}></Route>
          <Route path={"/" + lng} component={this.App}></Route>,
          <Route exact path="/acikrizametni" component={Policies} />
          <Route exact path="/cerezpolitikasi" component={Policies} />
          <Route exact path="/kvkk" component={Policies} />
        </div>
      </Router>
    );
  }
}

export default Main;
