import React from "react";
import { useLocation } from "react-router-dom";

export function Policies() {
  const locations = {
    "/acikrizametni": "acikriza",
    "/cerezpolitikasi": "cerez",
    "/kvkk": "kvkk",
  };

  const router = useLocation();
  const pathname = router.pathname;
  const title = `${locations[pathname]}`;

  console.log(title);
  if (title === "acikriza") {
    return (
      <>
        <div className="content">
          <h1>Açık Rıza Metni</h1>
          <p>
            Arşimet Dijital Çözümler ve Yazılım Sistemleri A.Ş.
            <strong> (“Arşimet”)</strong> Kullanıcı Kişisel Verilerin
            İşlenmesine Yönelik Aydınlatma Metni’nin 
            <strong>(“Aydınlatma Metni”)</strong>
             tamamını okudum, anladım ve Arşimet’in Kişisel Verilerimi
            Aydınlatma Metninde belirtilen amaçlar çerçevesinde işlemesi
            konusunda bilgilendirildim. Bu kapsamda kişisel verilerimin;
            Kullanıcılara yönelik kampanyaların oluşturulması, Arşimet iş
            ortakları tarafından Arşimet ürünlerinde kullanabilecekleri özel
            fırsatların sunulması, çapraz satış yapılması, hedef kitle
            belirlenmesi, Müşteri hareketlerinin takip edilerek kullanıcı
            deneyimini arttırıcı faaliyetlerin yürütülmesi, uygulamaların
            işleyişinin geliştirilmesi ve Müşteri ihtiyaçlarına göre
            kişiselleştirilmesi, Kullanıcı Analizi yapılması, doğrudan ve
            doğrudan olmayan pazarlama, kişiye özel pazarlama ve yeniden
            pazarlama faaliyetlerinin yürütülmesi, kişiye özel segmentasyon,
            hedefleme, analiz ve şirket içi raporlama faaliyetlerinin
            yürütülmesi, pazar araştırmaları, müşteri memnuniyeti
            aktivitelerinin planlanması ve icrası, ürünler kapsamında Arşimet’in
            sunduğu hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları
            ve ihtiyaçlarına göre özelleştirilerek ilgili kişilere önerilmesi ve
            tanıtılması ile müşteri ilişkileri yönetimi süreçlerinin planlanması
            ve icrası amaçları da dahil olmak üzere genel anlamda Arşimet’in
            ürün ve/veya hizmetlerinin satış ve pazarlama süreçlerinin
            planlanması ve icrası, Arşimet’in sunduğu ürün ve/veya hizmetlere
            bağlılık oluşturulması ve/veya arttırılması süreçlerinin planlanması
            ve icrası gibi amaçların gerçekleştirilmesi için her türlü kanallar
            aracılığıyla Aydınlatma Metni’nde yer alan bilgiler ışığında
            işlenmesine ve kanuni ya da hizmete ve/veya iş ilişkisine bağlı
            fiili gereklilikler halinde Aydınlatma Metni’nde belirtilen kişiler
            ile paylaşılmasına; (i) kişisel verilerimin işlenip işlenmediğini
            öğrenme, (ii) kişisel verilerim işlenmişse, buna ilişkin bilgi talep
            etme, (iii) kişisel verimin işleme amacını ve verilerimin amaca
            uygun kullanılıp kullanılmadığını öğrenme, (iv) kişisel verilerimin
            aktarıldığı üçüncü kişileri öğrenme ve (v) kişisel verilerim eksik
            ya da yanlış işlenmişse bunların düzeltilmesini isteme ve bunun
            kişisel verilerimin aktarıldığı üçüncü kişilere bildirilmesini talep
            etme ve (vi) kişisel verilerimin Kanun’a aykırı işlenmesi sebebiyle
            zarara uğramam durumunda tazminat talep etme haklarımı saklı tutmak
            kaydıyla, konu hakkında tereddüde yer vermeyecek şekilde
            aydınlatılmış ve bilgi sahibi olarak, açık şekilde rıza verdiğimi
            kabul ve beyan ederim.
          </p>
        </div>
      </>
    );
  } else if (title === "cerez") {
    return (
      <>
        <div className="content">
          <h1>Çerez Politikası</h1>
          <p>
            Çerezler, kullanıcı bilgisayarlarında depolanan küçük dosyalardır.
            Bu dosyalar web sitesinin kullanıcının ihtiyaçlarına daha iyi
            karşılamasını sağlamak amacı ve kullanıcılara özel hizmetler
            sunulması için kullanılmaktadır. Bu çerezler öncelikle 6698 sayılı
            Kişisel Verilerin Korunması Kanunu (“KVK Kanunu”) olmak üzere ilgili
            mevzuata uyumlu bir şekilde kullanılmaktadır.
          </p>
          <h2>Çerezlerin Kullanım Amacı</h2>
          <div className="cookies">
            <h2>Çerezler;</h2>
            <ul>
              <li>
                Web sitesinde kullanılacak ve web sitesinin doğru ve aktif
                kullanılması için gerekli temel fonksiyonları geliştirmek,
              </li>
              <li>
                Kullanıcı tarafından web sitesi ziyaret edildiğinde kullanıcı
                tercihlerinin kaydedilerek bir sonraki ziyaretlerinde
                ihtiyaçlarına uygun teklifler ve hizmetler sunmak,
              </li>
              <li>
                Web sitesinin ziyaret edenler için web sitenin kullanımını
                kolaylaştırma ve işlevini arttırmak,
              </li>
              <li>
                Web sitesinin her bir müşterimize özel olarak
                kişiselleştirilmesi ile pazarlama ve reklamcılık faaliyetlerinin
                gerçekleştirilmesini sağlamak amacıyla kullanılmaktadır.
              </li>
            </ul>
          </div>
          <div className="cookies">
            <h2>Kullanılan Çerez Türleri ve İşlevleri;</h2>
            <ul>
              <li>
                Sitemizde kalıcı ve geçici olmak üzere iki tür çerez
                bulunmaktadır. Kalıcı çerezler; belli çerezlerin kullanımı
                sitemizin doğru biçimde çalışması için zorunludur. Web sitemizde
                oturum açtığınızda oturumunuzun yeni sekmeler açtığınızda
                kapanmamasının sağlanması örnek olarak gösterilebilir. Geçici
                çerezler ise oturum ve ziyaret sürecinde depolanan ve
                tarayıcınızı kapattıktan sonra en kısa sürede silinen
                çerezlerdir.
              </li>
              <li>
                Bu çerezler sizlerin web sitesi üzerindeki tercihlerini ve
                seçimlerinizi hatırlayarak web sitemizde sunulan hizmetlerin
                sizin için kişiselleşmesini sağlamaktadır.
              </li>
              <li>
                Bu çerezler sayesinde Sitemizi kullanımınızı ve Sitemizin
                performansını analiz ederek sizlere verdiğimiz hizmetleri daha
                iyi hale getirebiliyoruz.
              </li>
              <li>
                Tarayıcınızın ayarlarını değiştirerek çerezlere ilişkin
                tercihlerinizi kişiselleştirme imkanına sahipsiniz. Bu şekilde
                tarafınıza çerezlerin kullanımını kontrol etme imkanı
                sağlanmaktadır. Ancak, çerez kullanımını engellemeniz durumunda
                tarafımızın internet üzerinden sağladığı bazı hizmetleri
                alamayabilir ya da size özel fırsatları göremeyebilirsiniz.
              </li>
              <li>
                Çerez Politikası kapsamında sitemizde kullandığımız çerezlerin
                kullanım ayarlarını türlerini değiştirilebilir veya sitemizde
                kullanılmak üzere yeni çerezler eklenebilir. İşbu Çerez
                Politikası hükümlerini dilediğimiz zaman değiştirme hakkını
                saklı tutarız.
              </li>
              <li>
                KVK Kanunu’nun 5. Ve 8. Maddeleri uyarınca ve/veya ilgili
                mevzuattaki istisnaların varlığı halinde kişisel verileriniz
                mevzuat uyarınca gerekli olan hallerde, rızanız olmaksızın, aksi
                hallerde rızanız doğrultusunda yukarıdaki amaçlar doğrultusunda
                işlenebilecektir. Arşimet Dijital Çözümler ve Yazılım Sistemleri
                A.Ş. olarak, Çerez Politikası kapsamındaki kişisel verilerinizi
                yukarıda belirtilen amaçların gerçekleştirilebilmesi ile sınırlı
                olarak ve mevzuata uygun şekilde kanunen yetkili kurumlar, iş
                ortaklarımız, tedarikçilerimiz, hissedarlarımız ve iş
                ortaklarımız ile paylaşabiliriz. Verilerin aktarıldığı
                tarafların kişisel verilerinizi dünyanın her yerindeki
                sunucularında saklayabileceğini belirtmek isteriz.
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  } else if (title === "kvkk") {
    return (
      <>
        <div className="content">
          <h1>
            KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA GİZLİLİK POLİTİKASI VE
            AYDINLATMA METNİ
          </h1>
          <p>
            İşbu Gizlilik Politikası ve Aydınlatma Metni’nde, Yahya Kemal
            Mahallesi Sair Sok No:1 Kat:1 Kağıthane/ İstanbul adresinde
            faaliyette bulunan Arşimet Dijital Çözümler ve Yazılım Sistemleri
            A.Ş. <strong>(“Arşimet”)</strong> tarafından işletilmekte olan
            <strong> Arşimet </strong>Web Uygulaması’nın (“Arşimet Web”),
            <strong> Arşimet </strong>
            Websitesi’nin (“Websitesi”) işletilmesi sırasında Mobil Uygulama ve
            Websitesi Kullanıcıları (“Veri Sahibi”) tarafından
            <strong> Arşimet </strong>ile paylaşılan veya
            <strong> Arşimet’in</strong>, Veri Sahibi’nin Mobil Uygulama’yı ve
            Websitesini kullanımı sırasında ürettiği kişisel verilerin, 6698
            sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) ve ilgili
            mevzuata uygun olarak işlenmesine ilişkin koşul ve şartlara yer
            verilmiştir. Kullanıcılara ait verilerin, güvenli şekilde
            barındırılması sağlanmakta ve olası hukuka aykırı erişimlere karşı
            gerekli tüm güvenlik tedbirlerini alınmaktadır. İşbu Gizlilik
            Politikası ve Aydınlatma Metni ile, Mobil Uygulama ve Websitesi
            üzerinden sunmakta olduğumuz hizmetler çerçevesinde toplanan kişisel
            verilerinizin, hangi kapsamda işlendiği açıklanmaktadır.
          </p>
          <div>
            <h2>1.Veri Sorumlusu</h2>
            <p>
              Kişisel verileriniz, veri sorumlusu sıfatıyla
              <strong>Arşimet</strong> nezdinde ilgili mevzuata uygun olarak ve
              aşağıda belirtilen usullerde işlenmektedir.
            </p>
            <h2>
              2.Kişisel Verilerinizin Toplanma Yöntemi ve İşlemenin Hukuki
              Sebepleri
            </h2>
            <p>
              <strong>2.1.Arşimet</strong> nezdinde yürütülen ticari faaliyet
              kapsamında toplanan tüm bilgiler, <strong>Arşimet </strong>
              nezdinde yürütülen süreçlerde kullanılmak adına,
              <strong> Arşimet’in </strong>
              faaliyetlerinin hukuka uygun olarak yürütülmesi için
              işlenmektedir. Bu faaliyetlerin kapsamında temin edilen kişisel
              veriler ve özel nitelikli kişisel veriler, sözlü, yazılı,
              elektronik ortamda temin edilmektedir. Kişisel verileriniz,
              <strong> Arşimet </strong>tarafından tamamen veya kısmen otomatik
              olan ya da herhangi bir veri kayıt sisteminin parçası olmak
              kaydıyla otomatik olmayan yollarla, Mobil uygulama ve Websitesi
              vasıtasıyla, sözleşmenin kurulması veya ifası, meşru menfaat,
              hukuki yükümlülük, bir hakkın tesisi, kullanılması veya korunması
              ve açık rıza gerektiren faaliyetler bakımından açık rıza hukuki
              sebepleri kapsamında toplanmaktadır.
            </p>
            <p>
              <strong>2.2. </strong>Aksi açıkça belirtilmedikçe, işbu Gizlilik
              Politikası kapsamında arz edilen 
              <strong>
                hüküm ve koşullar kapsamında “kişisel veri” ifadesi aşağıda yer
                alan bilgileri kapsayacaktır.
              </strong>
            </p>
            <ul>
              <li>
                <strong>
                  Kimlik ve İletişim Bilgisi: ad, soyad, telefon, e-posta adresi
                </strong>
              </li>
              <li>
                <strong>
                  Kullanıcı Bilgisi, Kullanıcı İşlem Bilgisi ve Finansal Bilgi:
                </strong>
                Üyelik bilgileri, üyelik ID numarası, Mobil Uygulama
                hizmetlerinden yararlandığınız tarih ve saate ilişkin veriler,
                Mobil Uygulama, Websitesi veya Canlı Destek ile iletişime geçme
                nedenleriniz.
              </li>
              <li>
                <strong>İşlem Güvenliği Bilgisi:</strong>
                 log-in credential bilgileri, parola bilgileri, kullanıcı
                hareketleri
              </li>
              <li>
                <strong>Lokasyon Verisi: </strong>
                Sistemin doğru şekilde kullanımı ve güvenlik için alınan konum
                bilgileri,
              </li>
              <li>
                <strong>Pazarlama Bilgisi: </strong>
                Alışkanlıklarınız ile beğenilerinizi gösteren raporlar ve
                değerlendirmeler, hedefleme bilgileri, çerez (cookie) kayıtları
                vb. bilgiler
              </li>
              <li>
                <strong>Talep/Şikayet Yönetimi Bilgisi:</strong>
                 Mobil Uygulama,websitesi ve çağrı merkezi üzerinden yaptığınız
                talep ve şikayetler ile paylaştığınız yorumlarınız
              </li>

              <li>
                <strong>Risk Yönetimi Bilgisi: </strong>IP adresi, Cihaz bilgisi
              </li>
            </ul>
            <p>
              <strong>2.3.</strong>Kanunun 3. ve 7. maddeleri çerçeve anonim
              hale getirilen veriler, anılan kanun hükümleri uyarınca kişisel
              veri olarak kabul edilmeyecek ve bu verilere ilişkin işleme
              faaliyetleri işbu Gizlilik Politikası hükümleri ile bağlı
              olmaksızın gerçekleştirecektir.
            </p>
            <p>
              <strong>2.4.</strong>KVKK’nın 5’inci ve 6’ncı maddelerinde
              belirtilen kişisel veri işleme şartları, ürün veya hizmetlere
              ilişkin kullanıcı bilgilendirmelerinin gerçekleştirilmesi (örn.
              yasal bilgilendirmeler, hesap hareketleri bildirimleri), muhasebe
              ve finans işlemleri, müşteri ilişkileri yönetimi, talep ve/veya
              şikayetlerin değerlendirilmesi ile çözüme kavuşturulması,
              <strong> Arşimet </strong>
              tarafından sunulan hizmetlerden kullanıcılarımızı faydalandırmak
              için gerekli çalışmaların iş birimlerimiz tarafından yapılması,
              bilgi güvenliğinin sağlanması, hesaba güvenli giriş işlemlerinin
              gerçekleştirilebilmesi, verilerin doğruluğunun ve kalite
              standartlarının sağlanması ile mevzuattan kaynaklı yükümlülüklerin
              yerine getirilmesi süreçleri kapsamında işlenen kişisel veri
              kategorileriniz ile söz konusu kişisel verilerinizin işlenme
              amaçları aşağıda detaylı şekilde açıklanmaktadır.
            </p>
            <p className="category" style={{ color: "white" }}>
              Kişisel Veri Kategorisi
            </p>
            <p className="category" style={{ color: "white" }}>
              Kişisel Veri İşleme Araçları
            </p>
            <p>İletişim Verisi</p>
            <p>(isim-soyisim , telefon numarası, e-posta adresi)</p>
            <ul>
              <li>Bilgi Güvenliği Süreçlerinin Yürütülmesi</li>
              <li>Faaliyetlerin Mevzuata Uygun Yürütülmesi</li>
              <li>Finans ve Muhasebe İşlerinin Yürütülmesi</li>
              <li>Hukuk İşlerinin Takibi Ve Yürütülmesi</li>
              <li>İletişim Faaliyetlerinin Yürütülmesi</li>
              <li>İş Faaliyetlerinin Yürütülmesi / Denetimi</li>
              <li>İş Sürekliliğinin Sağlanması Faaliyetlerinin Yürütülmesi</li>
              <li>Mal / Hizmet Üretim Ve Operasyon Süreçlerinin Yürütülmesi</li>
              <li>Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</li>
              <li>Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi</li>
              <li>Talep / Şikayetlerin Takibi</li>
              <li>Veri Sorumlusu Operasyonlarının Güvenliğinin Temini</li>
              <li>Yetkili Kişi, Kurum Ve Kuruluşlara Bilgi Verilmesi</li>
              <li>Mal / Hizmet Satış Süreçlerinin Yürütülmesi</li>
              <li>Sözleşme Süreçlerinin Yürütülmesi</li>
            </ul>
            <p>Lokasyon Verisi</p>
            <p>(açık rızanın varlığı halinde)</p>
            <ul>
              <li>Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</li>
              <li>
                Yakın Üye İşyeri butonunun kullanılması halinde, en yakın Üye
                İşyeri’nin gösterilmesi
              </li>
              <li>
                Üye İşyeri Potansiyeli taşıyan bölgelere ilişkin bilgi
                edinilmesi Finans Verisi
              </li>
            </ul>
            <p>Finans Verisi</p>
            <p>
              (Bakiye yükleme ve harcama işlem bilgisi verisi) (Mobil uygulama
              ve Websitesi üzerinden bakiye yükleme amacıyla gireceğiniz
              kart/banka bilgileri işlemin tamamlanmasından sonra Şirketimizce
              muhafaza edilmemektedir.)
            </p>
            <ul>
              <li>Faaliyetlerin Mevzuata Uygun Yürütülmesi</li>
              <li>Finans ve Muhasebe İşlerinin Yürütülmesi</li>
              <li>Hukuk İşlerinin Takibi Ve Yürütülmesi</li>
              <li>Yetkili Kişi, Kurum Ve Kuruluşlara Bilgi Verilmesi</li>
              <li>Mal / Hizmet Satış Süreçlerinin Yürütülmesi</li>
              <li>Sözleşme Süreçlerinin Yürütülmesi</li>
            </ul>
            <p>Pazarlama Bilgisi</p>
            <ul>
              <li>
                Alışkanlıklarınız ile beğenilerinizi gösteren raporlar ve
                değerlendirmeler, hedefleme bilgileri, çerez (cookie) kayıtları
                vb. bilgiler.
              </li>
            </ul>
            <p>İşlem Güvenliği Verisi</p>
            <p>(Güvenli giriş işlemlerinin temini amacıyla)</p>
            <ul>
              <li>Bilgi Güvenliği Süreçlerinin Yürütülmesi</li>
              <li>Faaliyetlerin Mevzuata Uygun Yürütülmesi</li>
              <li>Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</li>
              <li>Yetkili Kişi, Kurum Ve Kuruluşlara Bilgi Verilmesi</li>
            </ul>
            <p>Talep / Şikayet Verisi</p>
            <p>
              (Destek hattından iletişime geçildiğinde, sorunun çözümüne ilişkin
              bilgiler)
            </p>
            <ul>
              <li>Faaliyetlerin Mevzuata Uygun Yürütülmesi</li>
              <li>Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</li>
              <li>Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi</li>
              <li>Talep / Şikayetlerin Takibi</li>
            </ul>
            <h2>3.Kişisel Verilerinizin Üçüncü Kişilerle Aktarılması</h2>
            <p>
              <strong>Arşimet </strong>nezdinde paylaşılmış olan kişisel
              verilerinize, yalnızca yukarıda detaylarına yer verilen amaçlar
              bakımından sınırlı yetki erişimine sahip çalışanlarımız tarafından
              kendi görevlerini yerine getirmek amacıyla gerekli ölçüde
              erişilebilmektedir.
            </p>
            <p>
              Öte yandan toplanan kişisel verileriniz, KVKK’nın 8’inci ve 9’uncu
              maddeleri uyarınca kanunlarda açıkça öngörülmesi, meşru menfaat,
              veri sorumlusunun hukuki yükümlülüğü, bir hakkın tesisi,
              kullanılması veya korunması ve açık rıza gerektiren faaliyetler
              bakımından açık rızanızın varlığı halinde;
            </p>
            <ul>
              <li>
                <strong>Arşimet </strong>faaliyetleri gereği, hizmetin
                yürütülmesi bakımından ürün ve/veya hizmet temin edilmesi
                amaçlarıyla; üründe yer alan mevcut müşterilere, ileride yer
                alacak olan müşterilere, yurt içinde yerleşik tedarikçilerimize,
                (örn. çağrı merkezi, mobil altyapı sağlayan bilişim
                teknolojileri ve veri barındırma hizmet sağlayıcıları),
                danışmanlarımıza, ihtilaf halinde hukuk danışmanlarımıza, grup
                şirketlerimize, alt işverenlere, program-hizmet ortağı şahıs ve
                kuruluşlara, ilgili resmi kurumlara ve istisnai olarak özel
                kişilere aktarılabilmektedir. Kişisel verilerin işlenme amacı
                ile verilerin aktarım amacı paralellik göstermektedir.
              </li>
              <li>
                Olası şirket birleşme, devralma, ortak girişim, bölünme ve sair
                yapısal değişikliklerde Şirketimizin hukuki ve finansal inceleme
                faaliyetlerinin gerçekleştirilmesi amaçlarıyla potansiyel alıcı,
                satıcı veya işlemin muhatabı sair taraflar ve ilgili sürece
                dahil olan veri tabanı hizmet sağlayıcılarına,
              </li>
              <li>
                Şirketimizin hukuki yükümlüklerinin yerine getirilmesi ile hukuk
                işlerinin takibi amaçlarıyla kanunen yetkili kuruluşlara ve özel
                kişilere, aktarılabilecektir. Kişisel verileriniz, yurt içinde
                ve yurt dışında yerleşik yukarıda detayları yer alan üçüncü
                kişilere yine bu sınırlar dahilinde aktarılabilmektedir.
              </li>
            </ul>
            <h2>4.Verilerinizin Güvenliğinin Sağlanması</h2>
            <p>
              Üyelik bilgileri ve diğer kişisel verileriniz, üçüncü kişilerle,
              ilgili kanuna uygun olmayan şekilde paylaşılamaz ve bu verilerin
              güvenliğinden ilgili kanun uyarınca <strong>Arşimet </strong>
              sorumludur. <strong>Arşimet </strong>ilgili mevzuatta belirlenen
              veya işbu Gizlilik Politikası’nda ifade edilen şartlarda,
            </p>
            <ul>
              <li>kişisel verilerin hukuka aykırı olarak işlenmemesini,</li>
              <li>kişisel verilere hukuka aykırı olarak erişilmemesini ve</li>
              <li>
                kişisel verilerin muhafazasını sağlamak amacıyla uygun şartlar
                maliyetler gözetilerek/ asgari güvenlik düzeyini temin etmeye
                yönelik gerekli teknik ve idari tedbirleri almayı, gerekli
                denetimleri yaptırmaktadır. <strong>Arşimet </strong>ayrıca
                sizden elde ettiği kişisel verileri işbu Gizlilik Politikası ve
                Kişisel Verilerin Korunması Kanunu hükümlerine aykırı olarak
                başkasına açıklamamakta ve işleme amacı dışında
                kullanmamaktadır.
              </li>
            </ul>
            <h2>5.Gizlilik Politikası’ndaki Değişiklikler</h2>
            <p>
              İşbu Gizlilik Politikası değişen şartlara ve mevzuata uyum
              sağlamak amacıyla zaman zaman güncellenebilecektir.
            </p>
            <h2>6.Kişisel Verilerinizin İşlenme Süresi</h2>
            <p>
              Arşimet, sistemi kullanırken sağladığınız kişisel verileri,
              üyeliğin sona ermesinden itibaren 10 sene boyunca saklar.
              <strong> Arşimet’in </strong>Kullanıcı hesaplarının sağladığı
              kişisel verileri saklama süreleri, verilen hizmetlerin
              sağlanmasındaki süreklilik göz önüne alınarak belirlenmiştir. Süre
              geçtikten sonra kişisel verileriniz <strong>Arşimet </strong>
              tarafından veya talebiniz üzerine silinir, yok edilir veya anonim
              hale getirilir.
            </p>
            <h2>7.KVKK Kapsamında Haklarınız</h2>
            <p>
              Kişisel veri sahibi olarak KVKK’nın 11’inci maddesi uyarınca
              aşağıdaki haklara sahip olduğunuzu bildiririz:
            </p>
            <ul>
              <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
              <li>
                Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
              </li>
              <li>
                Kişisel verilerinizin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme,
              </li>
              <li>
                Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı
                üçüncü kişileri bilme,
              </li>
              <li>
                Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde
                bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
                kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini
                isteme,
              </li>
              <li>
                Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
                olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
                kalkması halinde kişisel verilerinizin silinmesini veya yok
                edilmesini isteme ve bu kapsamda ve kişisel verilerinizin eksik
                veya yanlış işlenmiş olması halinde yapılan işlemlerin kişisel
                verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
              </li>
              <li>
                İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                ortaya çıkmasına itiraz etme,
              </li>
              <li>
                Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğraması halinde zararın giderilmesini talep etme.
              </li>
            </ul>
            <p>
              Yukarıda belirtilen haklarınıza yönelik başvurularınızı, Veri
              Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ hükümlerine
              uygun şekilde yazılı olarak veya kayıtlı elektronik posta (KEP)
              adresi, güvenli elektronik imza, mobil imza veya Şirketimize daha
              önce bildirmiş olduğunuz ve sistemlerimizde kayıtlı olan e-posta
              adresiniz üzerinden elektronik olarak Şirketimizin gerçek hak
              sahibi olduğunuzun tespitini gerçekleştirebilmesi amacıyla
              kimliğinizi doğrulayan bilgilerle/belgelerle*
              birlikte info@arsimet.net adresinden ulaşabileceğiniz İlgili Kişi
              Başvuru Formu’nu doldurarak Arşimet’e iletebilirsiniz. Talebinizin
              niteliğine göre en kısa sürede ve en geç 30 (otuz) gün içinde
              başvurularınız ücretsiz olarak sonuçlandırılacaktır; ancak işlemin
              ayrıca bir maliyet gerektirmesi halinde Kişisel Verileri Koruma
              Kurulu tarafından belirlenecek tarifeye göre tarafınızdan ücret
              talep edilebilecektir.
            </p>
            <p style={{ fontSize: "12px" }}>
              *Başvuru esnasında kimliğinizi doğrulamaya yarayan T.C. kimlik
              numarası veya diğer ülke vatandaşları için pasaport numarası,
              tebligata esas yerleşim yeri adresi/iş yeri adresi, cep
              telefonu/telefon/faks numarası, e-posta adresi vb. bilgilerin,
              belgelerle birlikte başvuru evrakına eklenmesi gerekmektedir.
              Başvuru sırasında hiçbir şekilde özel nitelikli kişisel veri
              (sağlık bilgisi, din bilgisi, etnik köken bilgisi gibi.)
              paylaşılmamasına özen göstermenizi rica ederiz.
            </p>
            <h2>8.Çerez Politikası</h2>
            <p>
              <strong>Arşimet’in </strong>kullanımı sırasında; hizmetlerimizi
              geliştirmek ve daha kaliteli ve etkin bir hizmet sunabilmek için
              temel hak ve özgürlüklerinize zarar gelmemesi gözetilerek, meşru
              menfaatlerimiz kapsamında ve bazı çerezler kullanılabilmektedir.
              Bu konuda detaylı bilgi için <strong>Arşimet </strong>çerez
              politikamıza internet sitesinden linkinden ulaşabilirsiniz. Üye,
              Bireysel Kullanıcı Sözleşmesini ve işbu Gizlilik Politikası ve
              Aydınlatma Metni’ni kabul ettiğinde
              <strong> Arşimet’e </strong>ait sistemlerde çerez kullanıldığını
              anladığını ve bundan dolayı <strong>Arşimet’ten </strong>her ne
              şekilde olursa olsun herhangi bir tazminat talebinde
              bulunmayacağını kabul ve taahhüt eder.
            </p>
            <p>Arşimet Dijital Çözümler ve Yazılım Sistemleri A.Ş.</p>
          </div>
        </div>
      </>
    );
  }
}
