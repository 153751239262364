import i18next from 'i18next';

i18next
  .init({
    interpolation: {
      escapeValue: false,
    },
    lng: 'en',
    resources: {
      en: {
        translation: {
          seo: {
            title: 'Arsimet Digital Archive',
            description: 'Arsimet is an advanced digital archiving system which can digitalize your physical documents as well as to manage & digitalize any folders such as voice, image, photos and videos on the same platform.',
            keywords: 'archiving,digital archive,physical document,scan,twain',
          },
          alert: {
            success: "Message Sent!",
            failed: "Message Could Not Be Sent!"
          },
          mainTitle: 'ARSIMET DIGITAL ARCHIVE',
          about: {
            title: "WHAT IS ARSIMET?",
            text: "<b>ADA</b> (Arsimet Digital Archive) is an advanced digital archiving system which can digitalize your physical documents as well as to manage & digitalize any folders such as voice, image, photos and videos on the same platform.",
            watch:"Watch Now"
          },
          properties: {
            title: "FEATURES",
            accordion1: {
              title: "Fast & Secure Search",
              text: "ADA is a high tech digital archiving system which can show all the desired results in a fast & secure way due to its private database search. Through its fast searching function, searching a file on the system is 40 times faster than SQL."
            },
            accordion2: {
              title: "Opening Big Sized Project Files",
              text: "One of the main problem of the software’s is to display and download big project files. ADA, can open any projects whose sizes are bigger than 1 GB / 10 GB / 100 GB in milliseconds."
            },
            accordion3: {
              title: "Video, Photo and Voice Records Archive",
              text: "Our Archiving System does not only archives letters; it also makes voice, photo and video archiving. Any image or voice records can be added to the system and can be searched after being archived. The system can make automatic tagging from images and videos; by identifying faces from pictures and videos from your digital archive."
            },
            accordion4: {
              title: "Dynamic Inventory Lists",
              text: "Any inventory can be added and searched on the system through the dynamic inventory list. All kinds of excel files can be imported to the system with Folder Plan Module."
            },
            accordion5: {
              title: "Logical Query/Search",
              text: "You can search any index area with Logical Query Search Module. For instance; any folders whose block number larger than 100, map section less than or equal to 200 and surface larger than 250 sq. can be searched on the system."
            },
            accordion6: {
              title: "Loan/Charge and E-expertise Structure",
              text: "Loan/Charge module helps the client to not take out any documents from physical archives. The requested documents can be sent among the departments inside or outside of the company. Having this feature prevents losing or damaging any physical documents."
            },
            accordion7: {
              title: "Advanced Authentication",
              text: "Through Advanced Authentication Module, you can create Authorized groups, private authentication to the groups or to users."
            },
            accordion8: {
              title: "Edit texts embedded in your images with OCR",
              text: "OCR is a global solution to edit, convert and transform all your physical data and documents into a variety of digital formats, with a few clicks and automatically transforms images to the correct text reading in multiple languages."
            },
            accordion9: {
              title: "Object Detection",
              text: "Arsimet Object Detection allows you to recognize animals, objects or other targeted images and automatically assigns labels for all objects. System can organize data includes classification, inconvenient image detection and real-time image detection in order to analyse videos effectively."
            },
            accordion10: {
              title: "Biometric Facial Recognition System",
              text: "The Biometric Facial Recognition System ensures detecting and identifying a person or multiple people from a digital image, video or live stream."
            },
            accordion11: {
              title: "Signature Detection",
              text: "Arsimet indicates when there is a signature on the document and makes it searchable."
            },
            accordion12: {
              title: "Voice to Text Recognition",
              text: "Our Speech to Text Detection converts any kind of audio files to text. The text can be searchable by any key word."
            },
            ocr: {
              title: "WE EMPOWER BUSINESSES TO FUTURE TECHONOLOGIES WITH OUR ADVANCED OCR, FACE & IMAGE RECOGNITION SYSTEMS",
              text1: "OCR",
              text2: "OBJECT RECOGNITION",
              text3: "FACE RECOGNITION"
            },
          },
          solutions: {
            title: " INDUSTRY SOLUTIONS",
            list1: {
              title: "MUNICIPALITY & GOVERNMENT",
              text1: "Easy Integration with third-party Systems",
              text2: "AI based archive system",
              text3: "Easily to reach any document with OCR",
              text4: "Opening Big Infrastructure Project in 1 millisecond",
              text5: "Time and Labour Saving",
              text6: "Local Cloud Store Option",
              text7: "Mobile Friendly",
              text8: "Photo & Video Archive Searching Feature"
            },
            list2: {
              title: "LAW",
              text1: "AI based archive system",
              text2: "Easily to reach any document with OCR",
              text3: "Local Cloud Store Option",
              text4: "Logical Query & Search",
              text5: "Time and Labour Saving",
              text6: "Mobile Friendly",
              text7: "Easy Integration with third-party Systems",
              text8: "Multiple Language OCR",
              text9: "Physical Archive Management"
            },
            list3: {
              title: "CONSTRUCTION",
              text1: "Opening Big Infrastructure Projects in 1 millisecond",
              text2: "Easily to reach any document with OCR",
              text3: "Logical Query & Search",
              text4: "Mobile Friendly",
              text5: "Compatible with many formats",
              text6: "Time and Labour Saving",
              text7: "Easy Integration with third-party Systems"
            }
          },
          others: {
            title: "OTHER SECTORS",
            text1: "Our Software is compatible to all kind of sectors.",
            text2: "The system compatible with hundreds of formats.",
            mobileText: "Toursim, Commerce, Health Service, Pharmaceutical, Hospitality, Banking, Transportation, Financial Services",
            prop1: "TOURISM",
            prop2: "commerce",
            prop3: "health servIce",
            prop4: "pharmaceutIcal",
            prop5: "hospItalIty",
            prop6: "bankIng",
            prop7: "transportatIon",
            prop8: "FINANCIAL SERVICES"
          },
          advantages: {
            title: "ADVANTAGES",
            para1: {
              title: "Dynamic Platform",
              text: "Document fields are dynamicly created so it can be changed all the time depend on your wish and you can easily design your own system."
            },
            para2: {
              title: "Mobile Compatible Design",
              text: "We fit millions of folders into your mobile. Apart from reaching to all your digital files on PC; it also gives an access through all smart devices due to its mobile platform design."
            },
            para3: {
              title: "User Friendly Functions",
              text:"ADA contains many user-friendly operational functions on the system. For instance, the user can identify his/her own preferred folders or the user can compose tags for the specific files through assigning colour codes to the files."
            },
            para4: {
              title: "Advanced and Fast Search",
              text: "The unique differentiation of the Archive Systems is to make searching and easily to reach the data than just making the data digitalized. ADA, whether its background or presents its logical queries, it provides you any data in a shortest and in a right way."
            },
            para5: {
              title: "Display Performance",
              text: "Regardless of your file sizes, ADA can guarantee to access and display any of your file on digital archive in miliseconds."
            },
            para6: {
              title: "Management Panel",
              text: "The system represents the summary info for daily basis and informs the user for the current status."
            }
          },
          demo: {
            title: "ADA can guarantee to access and display any of your file on digital archive in milliseconds."
          },
          contact: {
            title: "CONTACT US",
            text1: "Yahyakemal Mah. Şair Sok. No. 1 Kat 1 Kağıthane - İSTANBUL",
            text2: "TTC 2800 Lively Blvd Eld Groove Village IL 60007-6733 - USA",
            text3: "Barış Mah. Koşu Yolu Cad. No:26 İç Kapı No:1 Gebze - Kocaeli",
            text4: "4-79B BOUL. Brunswick Dollard-Des-Ormeaux, Quebec H9B 2J5 - Canada",
            tel: "0850 640 0850",
            mail: "info@arsimet.net",
            button: "send"
          },
        },
      },
      tr: {
        translation: {
          seo: {
            title: 'Arşimet Dijital Arşiv',
            description: 'Arşimet tüm fiziksel evraklarınızı dijitalleştirmenizi sağlayan, aynı zamanda ses, görüntü, resim gibi dosyalarınızı tek bir platformda yönetmenize olanak sunan gelişmiş bir dijital arşiv sistemidir.',
            keywords: 'arşiv,dijital arşiv,fiziksel arşiv,tarama,twain',
          },
          alert: {
            success: "Mesajınız Gönderildi!",
            failed: "Mesajınız Gönderilemedi!"
          },
          mainTitle: 'ARŞİMET DİJİTAL ARŞİV',
          about: {
            title: "ARŞİMET NEDİR?",
            text: "<b>Arşimet</b>, dijitalleşen ve gelişen teknolojiye ayak uydurmak için tüm fiziksel evraklarınızı dijitalleştirmenizi sağlayan aynı zamanda ses, görüntü, resim gibi tüm dosyalarınızı tek bir platformda yönetmenize olanak sunan gelişmiş bir dijital arşiv sistemidir.",
            watch:"Şimdi İzle"
          },
          properties: {
            title: "ÖZELLİKLER",
            accordion1: {
              title: "Hızlı ve Güvenilir Arama",
              text: "ADA, aramalar için kullanılan özel veri tabanı sayesinde istenilen tüm aramaları en hızlı ve güvenli bir şekilde göstermektedir. Hızlı arama sayesinde, arama süresi SQL'e göre 40 kat daha hızlı arama yapmaktadır."
            },
            accordion2: {
              title: "Büyük Proje Dosyalarının Açılması",
              text: "Bilinen tüm yazılımlar büyük proje dosyalarını görüntülemekte ve yüklemekte zorlanmaktadır, M.D.A istenilirse 1 GB / 10 GB / 100 GB lık proje dosyalarını milisaniyeler içerisinde açmaktadır."
            },
            accordion3: {
              title: "Video, Photo ve Ses Kayıt Arşivi",
              text: "Dijital Arşiv sistemi sadece evrak arşiv yapmaz aynı zamanda ses ve görüntü arşivide yapar. Meclis ve Encümen tutanaklarına görüntü ve ses kayıtları da eklenebilir ve arşivlenip aranabilir."
            },
            accordion4: {
              title: "Dinamik Envanter Listeleri",
              text: "Dinamik envanter listesi sayesinde her türlü envanter sisteme eklenebilir ve aranabilir. Dinamik Excel İçe Aktarım Modülü sayesinde her türlü excel sisteme import edilebilir. Standart Dosya Planı modulü ile saklama ömrü dolmuş evrakların imhası sistem üzerinden bildirilecektir."
            },
            accordion5: {
              title: "Mantıksal Sorgu ile Arama",
              text: "Mantıksal sorgulama sayesinde tüm index alanlarını arayabiliriniz. Örn : Adası 100’den büyük olan, Paftası 200’den küçük eşit olan yüzölçümü 250 m2 üzerinde olan evraklar sistem üzerinden sorgulanabilir."
            },
            accordion6: {
              title: "Ödünç, Zimmet ve E-Ekspertiz Yapısı",
              text: "Ödünç/Zimmet Modülü ile arşivden fiziksel evrak çıkmamasını sağlamaktadır. İstenilen evrakların hepsi kurum içerisinde veya dışarısında dijital olarak gönderilecektir. Bu sayede arşiv evraklarının kaybolması, zarar görmesi gibi durumlar ortadan kalkacaktır."
            },
            accordion7: {
              title: "Gelişmiş Yetkilendirme",
              text: "Gelişmiş yetkilendirme sayesinde yetkilendirme grupları oluşturabilir, gruplara veya kişilere özel yetkilendirmeler yapılabilir."
            },
            accordion8: {
              title: "Herhangi bir fotoğrafta veya görüntüde yer alan yazıları dijital ortama aktarın",
              text: "OCR çözümü herhangi bir doküman veya basılı her tür belgenin tarayıcılar yardımıyla taranarak dijital ortamına aktarılmasıyla oluşan resim formatındaki dosyaların OCR sistemi ile piksel piksel incelenerek yazı veya resim olarak ayrıştırılıp resimlerin resim, harf, rakam olarak düzenlenmiş halidir. OCR ile taranan bu belgeler Word, Excel, Text gibi kelime işlemci veya pdf olarak kaydedilir."
            },
            accordion9: {
              title: "Nesnelerin Ötesine Uzanın",
              text: "Macellan Nesne Tanıma obje, hayvan ve diğer hedeflenmiş nesneleri tanır ve unları otomatik olarak etiketler. Sistem aynı zamanda nesnelere arası sınıflandırma, tanımlanamayan görüntü tespiti ve gerçek zamanlı görüntü tespiti yaparak görüntü ve videolar da verimli bir analiz yapmanıza olanak sağlar."
            },
            accordion10: {
              title: "Biyometrik Yüz Tanıma",
              text: "Biyometrik Yüz Tanıma size bir veya birden çok insanı herhangi bir fotoğraftan veya videodan otomatik olarak saptar ve o kişileri tespit etmenize yardımcı olur."
            },
            accordion11: {
              title: "İmza Tanıma",
              text: "Arşimet, taranan veya sisteme yüklenen herhangi bir dökümanda imza olup olmadığını gösterir ve belgeyi aranabilir hale getirir."
            },
            accordion12: {
              title: "Sesten Yazıya",
              text: "Konuşmadan Metne Algılama, her türlü ses dosyasını metne dönüştürür. Metin herhangi bir anahtar kelime ile aranabilir."
            },
            ocr: {
              title: "GELİŞMİŞ OCR, YÜZ VE NESNE TANIMA TEKNOLOJİLERİ İLE ŞİRKETLERİ GÜÇLENDİRİYORUZ",
              text1: "OCR",
              text2: "NESNE TANIMA",
              text3: "YÜZ TANIMA"
            },
          },
          solutions: {
            title: "ENDÜSTRİYEL ÇÖZÜMLER",
            list1: {
              title: "BELEDİYE & KAMU KURUMLARI",
              text1: "3.Parti sistem ve proglamlarla kolay entegrasyon",
              text2: "Yapay Zeka Arşiv Sistemi",
              text3: "OCR (Optik Karakter Okuyucu) ile her türlü dökümana hızlıca erişim",
              text4: "Büyük Yapı projelerini 1 milisaniyede açma",
              text5: "Zaman ve İşçilik Tasarrufu",
              text6: "Yerli Bulut Ağında Depolama Seçeneği",
              text7: "Akıllı telefondan açabilme ",
              text8: "Fotoğraf & Video Arşivi Arama Seçeneği"
            },
            list2: {
              title: "HUKUK",
              text1: "Yapay Zeka Arşiv Sistemi",
              text2: "OCR (Optik Karakter Okuyucu) ile her türlü dökümana hızlıca erişim ",
              text3: "Yerli Bulut Ağında Depolama Seçeneği",
              text4: "Mantıklı Sorgulama ve Arama Özelliği",
              text5: "Zaman ve İşçilik Tasarrufu",
              text6: "Akıllı telefondan açabilme özelliği",
              text7: "3.Parti sistem ve proglamlarla kolay entegrasyon",
              text8: "Bir çok dilde OCR (Optik Karakter Okuyucu) özelliği",
              text9: "Fiziksel Arşivleme Yönetimi"
            },
            list3: {
              title: "YAPI İNŞAAT",
              text1: "Büyük Yapı ve İnşaat projelerini 1 milisaniyede açma",
              text2: "OCR (Optik Karakter Okuyucu) ile her türlü dökümana hızlıca erişim ",
              text3: "Mantıklı Sorgulama ve Arama Özelliği",
              text4: "Akıllı telefondan açabilme özelliği",
              text5: "Bir çok formatla uyumlu",
              text6: "Zaman ve İşçilik Tasarrufu",
              text7: "3.Parti sistem ve proglamlarla kolay entegrasyon"
            }
          },
          others: {
            title: "DİĞER SEKTÖRLER",
            text1: "Dijital Arşiv yazılımı sistemi tüm sektörlerle,",
            text2: "yüzlerce değişik program formatı ile uyumludur.",
            mobileText: "TURİZM, TİCARET, SAĞLIK SERVİSİ, FARMASÖTİK, MİSAFİRPERVERLİK, BANKACILIK, TAŞIMACILIK, FİNANASAL SERVİSLER",
            prop1: "TURİZM",
            prop2: "TİCARET",
            prop3: "SAĞLIK SERVİSİ",
            prop4: "FARMASÖTİK",
            prop5: "MİSAFİRPERVERLİK",
            prop6: "BANKACILIK",
            prop7: "TAŞIMACILIK",
            prop8: "FİNANASAL SERVİSLER"
          },
          advantages: {
            title: "AVANTAJLAR",
            para1: {
              title: "Dinamik Altyapı",
              text: "Evraklarınızı dijitalleştirirken standartları size bırakıyoruz. ADA, kurumunuzun evraklarını belirli bir kalıba sokmak yerine, kurumun standartlarına uyar, sistemlerini kendilerinin kurmasına olanak sunar."
            },
            para2: {
              title: "Mobil Uyumlu Tasarım",
              text: "Kurumunuza ait milyonlarca dosyayı cebinize sığdırıyoruz. Dijital evraklarınıza bilgisayardan ulaşmanın yanı sıra tüm mobil platformlara uygun tasarımı sayesinde her türlü akıllı cihazdan erişimi destekler."
            },
            para3: {
              title: "Kullanımı Kolaylaştıran Fonksiyonlar",
              text:"ADA, içerisinde birçok kullanıcı dostu işlevsel fonksiyonlar barındırır. Örneğin, kullanıcı kendine ait öncelikli dosyalar tanımlayabilir veya belgelere renk kodları atayarak o belgeye ait etiketler oluşturabilir."
            },
            para4: {
              title: "Gelişmiş ve Hızlı Arama",
              text: "Arşiv sistemlerinde farkındalık yaratan özellik dijitalleştirmekten çok dijitalleşen arşiv içerisinde istediğin veriyi kolaylıkla ve hızlı şekilde bulmaktır. ADA, gerek kullandığı altyapı gerek sunduğu mantıksal sorgular sayesinde size istediğiniz veriyi en kısa ve doğru şekilde bulmanıza olanak sağlar."
            },
            para5: {
              title: "Görüntüleme Performansı",
              text: "Belgelerinizin boyutundan bağımsız olarak, ADA arşivinizdeki her türlü belgeye milisaniyeler içerisinde görüntülemenizi garanti eder."
            },
            para6: {
              title: "Yönetim Paneli",
              text: "Sistemde günlük olarak neler yapıldığına dair özet bilgiler sunarak, kullanıcıyı sistemdeki son durum hakkında bilgilendirir."
            }
          },
          demo: {
            title: "ADA arşivinizdeki her türlü belgeye milisaniyeler içerisinde görüntülemenizi garanti eder."
          },
          contact: {
            title: "BİZE ULAŞIN",
            text1: "Yahyakemal Mah. Şair Sok. No. 1 Kat 1 Kağıthane - İSTANBUL",
            text2: "TTC 2800 Lively Blvd Eld Groove Village IL 60007-6733 - USA",
            text3: "Barış Mah. Koşu Yolu Cad. No:26 İç Kapı No:1 Gebze - Kocaeli",
            tel: "0850 640 0850",
            mail: "info@arsimet.net",
            button: "gönder"
          },
        },
      },
    },
  })

export default i18next
